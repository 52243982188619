import { BasePaginatedData } from './common';

export enum ImportTemplateTypeEnum {
  NONE = '',
  PROJECT = 'project',
  PACKAGE_DOC = 'package-doc',
  PACKAGE_EXP = 'package-exp',
  PACKAGE_LAB = 'package-lab',
  PACKAGE_NOI = 'package-noi',
  PACKAGE_TPI = 'package-tpi',
  ITEM = 'item',
  STEP = 'step',
}

export type ImportTemplate = {
  id: string;
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
};

export type ImportTemplateBody = {
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
  upload_base64?: string;
  file_extension?: string;
};

export type ImportTemplateDetail = {
  id: string;
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
  preassigned_url?: string;
  upload_base64?: string;
  file_extension?: string;
};

export type ImportTemplateGeneralInfo = {
  type: ImportTemplateTypeEnum;
  type_check: boolean;
  version: string;
  version_check: boolean;
  key: string;
  key_check: boolean;
  has_hidden_worksheet: boolean;
};

export class ImporTemplatesPaginatedData extends BasePaginatedData<ImportTemplate> {}
