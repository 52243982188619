import api from 'api';
import { BasePaginatedRequest } from 'models/common';
import Table from 'components/commons/table/Table';
import { BasePaginatedData } from 'models/common';
import { PaginationElementType, TableColumnType } from 'models/table';
import { useEffect, useState } from 'react';

interface ServerSideTableProps<T> {
  columns: TableColumnType<T>[];
  rowClickHandler?: ((row: T, rIdx: number) => void) | undefined;
  fetchApi: (req: any, data?: any) => Promise<BasePaginatedData<T>>;
  fetchApiData?: any;
  customPaginatedRequest?: any;
  key?: string;
  forceFetchData?: boolean;
  onForceFetchDataCompleted?: () => any;
  search?: boolean;
}

function ServerSideTable<T = any>({
  columns,
  rowClickHandler = undefined,
  fetchApi,
  fetchApiData = null,
  customPaginatedRequest = undefined,
  key,
  forceFetchData = false,
  onForceFetchDataCompleted = undefined,
  search = false,
}: ServerSideTableProps<T>) {
  const [tableRows, setTableRows] = useState<T[]>([]);
  const [tableTotalRows, setTableTotalRows] = useState<number>(0);
  const [tablePage, setTablePage] = useState<PaginationElementType>({ limit: 5, offset: 0, q: '' });

  useEffect(() => {
    const request: BasePaginatedRequest = {
      limit: tablePage.limit,
      offset: tablePage.offset,
      q: tablePage.q,
    };

    const managedFetchApi = fetchApiData
      ? fetchApi(customPaginatedRequest === undefined ? request : customPaginatedRequest, fetchApiData)
      : fetchApi(customPaginatedRequest === undefined ? request : customPaginatedRequest);

    managedFetchApi
      .then((res) => {
        setTableRows(res.content);
        setTableTotalRows(res.total_count);
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .finally(() => forceFetchData && onForceFetchDataCompleted!());
  }, [tablePage, forceFetchData]);

  const searchValueChangeHandler = (q: string) => {
    setTablePage({ ...tablePage, q });
  };

  const pageChanged = (limit: number, offset: number, q = '') => {
    setTablePage({ limit, offset, q });
  };

  return (
    <>
      {search && <input className="table-search-bar" placeholder=" Search" onChange={(e) => searchValueChangeHandler(e.target.value)} />}
      <Table<T> columns={columns} rows={tableRows} rowClickHandler={rowClickHandler} key={key} onPageChanged={pageChanged} totalCount={tableTotalRows}></Table>
    </>
  );
}

export default ServerSideTable;
